import AppStoreButton from '@/assets/appstore-badge.svg';
import GoogleplayButton from '@/assets/google-play-badge.png';
import Iphone16 from '@/assets/iPhone 16.png';
import BottomDecoration from '@/assets/bottom decoration.png';
import splash from '@/assets/splash.png';
import { motion } from 'framer-motion';

function HeroSection() {
  return (
    <section
      id="hero"
      className="w-full main-section min-h-dvh flex flex-col lg:flex-row items-center pt-12 md:pt-16 lg:pt-20 overflow-hidden bg-center relative"
    >
      <div className="absolute inset-0 opacity-40 top-16 md:top-8 object-contain flex items-center justify-center">
        <img
          className="w-auto h-auto max-h-[90dvh] transform-gpu"
          src={splash}
          alt=""
          loading="lazy"
        />
      </div>
      <div
        className="absolute inset-0 bottom-0 bg-bottom bg-repeat-x bg-[auto_5dvh] lg:bg-[auto_7dvh] transform-gpu"
        style={{
          backgroundImage: `url(${BottomDecoration})`,
        }}
      ></div>

      {/* Content Section */}
      <div className="flex w-full lg:w-1/2 z-[2] text-primary-100 px-8 md:px-12 xl:px-24 py-8">
        <div className="flex flex-col items-center md:items-center lg:items-start w-full md:max-w-5xl gap-4 mt-4 md:mt-6">
          <div className="w-auto">
            <span className="rounded-3xl bg-primary-300/10 px-5 py-2 text-primary-100 md:text-base">
              Your Digital Hub for the Qur'an
            </span>
          </div>
          <h1 className="text-3xl/10 md:text-4xl/13 lg:text-5xl/16 font-medium max-w-lg md:max-w-xl lg:max-w-2xl text-center md:text-center lg:text-left">
            Experience the Qur'an as it Deserves to be Heard
          </h1>
          <p className="text-sm md:text-base lg:text-lg w-full text-center md:text-center max-w-lg md:max-w-xl lg:text-left lg:max-w-2xl">
            Access 200+ renowned reciters, multiple authentic rewayat, and a distraction-free
            environment. All completely free, with no advertisements. Your dedicated platform for
            the sacred Qur'an.
          </p>
          <div className="flex flex-col gap-2 mt-2">
            <div className="flex flex-row gap-4 md:gap-6 items-start">
              <div className="flex flex-col items-center">
                <motion.a
                  href="https://apps.apple.com/us/app/bayaan/id6648769980"
                  className="cursor-pointer transform-gpu"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2, ease: 'easeOut' }}
                >
                  <img
                    src={AppStoreButton}
                    alt="Download on App Store"
                    className="h-9 md:h-11 lg:h-12 w-auto transform-gpu"
                    loading="eager"
                  />
                </motion.a>
                <span className="text-xs invisible px-2 py-1 rounded mt-1">&nbsp;</span>
              </div>
              <div className="flex flex-col items-center">
                <motion.div
                  className="cursor-pointer transform-gpu opacity-50"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2, ease: 'easeOut' }}
                >
                  <img
                    src={GoogleplayButton}
                    className="h-9 md:h-11 lg:h-12 w-auto transform-gpu"
                    alt="Get it on Google Play"
                    loading="eager"
                  />
                </motion.div>
                <span className="text-xs bg-primary-300/20 text-white px-2 py-1 rounded mt-1">
                  Coming soon
                </span>
              </div>
            </div>
            <p className="text-xs text-primary-300 text-center lg:text-left mt-1">
              Free forever • No ads • Multiple rewayat • Offline access
            </p>
          </div>
        </div>
      </div>

      {/* iPhone Image Section - Desktop */}
      <div className="hidden lg:flex w-full lg:w-1/2 h-full relative justify-center items-center">
        <img
          src={Iphone16}
          className="max-h-[80vh] w-auto object-contain transform-gpu"
          alt="Bayaan App Interface"
          loading="lazy"
        />
      </div>

      {/* iPhone Image Section - Mobile */}
      <div className="flex lg:hidden w-full mt-4 md:mt-8 justify-center items-center pb-12">
        <img
          src={Iphone16}
          className="max-h-[50vh] md:max-h-[60vh] w-auto object-contain transform-gpu"
          alt="Bayaan App Interface"
          loading="lazy"
        />
      </div>
    </section>
  );
}

export default HeroSection;
