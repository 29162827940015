import * as React from 'react';
import { Outlet, createRootRoute } from '@tanstack/react-router';
import '@/App.css';
import SEO from '@/component/SEO';
import { Analytics } from '@vercel/analytics/react';

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  return (
    <React.Fragment>
      <SEO />
      <Outlet />
      <Analytics />
    </React.Fragment>
  );
}
