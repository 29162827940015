import React from 'react';
import decor from '@/assets/decor-1.png';
type CardProps = {
  title: string;
  span?: string;
  description: string;
  image?: string;
  flipped?: boolean;
};

function Card(props: CardProps) {
  return (
    <div
      className="relative bg-white/5 flex flex-col items-center py-9 md:py-14 px-8 md:px-8 rounded-3xl min-h-[475px] md:min-h-[605px] bg-bottom bg-no-repeat hover:scale-105 transform transition-transform duration-200 flex-1 min-w-0"
      style={{ backgroundImage: `url(${decor})` }}
    >
      <div className="max-w-72 md:max-w-[482px]  flex flex-col space-y-4 items-center">
        <img
          src={props.image}
          className={`absolute bottom-0 left-1/2 right-1/2 -translate-x-1/2 m-0 w-full max-w-[495px] ${
            props.flipped == true ? 'scale-x-[-1]' : ''
          }`}
          alt=""
        />
        {props.span && (
          <span className="rounded-2xl bg-primary-300/10 px-4 py-2 text-white font-semibold text-xs md:text-sm">
            {props.span}
          </span>
        )}
        <h1 className="text-xl md:text-[32px] font-medium xl:whitespace-nowrap">{props.title}</h1>
        <p className="text-sm md:text-[16px] font-light max-w-[400px]">{props.description}</p>
      </div>
    </div>
  );
}

export type { CardProps };

export default Card;
