import { createFileRoute } from '@tanstack/react-router';
import Navbar from '@/component/Navbar';
import HeroSection from '@/component/HeroSection';
import FeaturesSection from '@/component/FeaturesSection';
import ExperienceSection from '@/component/ExperienceSection';
import FirstCTA from '@/component/FirstCTA';
import SecondCTA from '@/component/SecondCTA';
import TestimonialSection from '@/component/TestimonialSection';
import FAQSection from '@/component/FAQSection';
import InfoSection from '@/component/InfoSection';
import Footer from '@/component/Footer';
import SEO from '@/component/SEO';
import { memo } from 'react';

export const Route = createFileRoute('/')({
  component: Index,
});

const MainContent = memo(() => (
  <main className="flex flex-col items-center bg-primary-900 relative font-primary">
    <div className="absolute bg-mesh pointer-events-none inset-0"></div>
    <HeroSection />
    <FeaturesSection />
    <ExperienceSection />
    <FirstCTA />
    <SecondCTA />
    <TestimonialSection />
    <FAQSection />
    <InfoSection />
    <Footer />
  </main>
));

function Index() {
  return (
    <>
      <SEO
        title="Bayaan - Your Digital Hub for the Qur'an"
        description="Experience the Qur'an as it deserves to be heard with Bayaan's free app featuring 200+ reciters, multiple rewayat, and offline access. No ads, no subscriptions, just pure Qur'an."
        canonical="/"
      />
      <Navbar />
      <MainContent />
    </>
  );
}
