import React from 'react';
import iphone17 from '@/assets/iphone 17.png';
import decor from '@/assets/decor-1.png';

function SecondCTA() {
  return (
    <section className="flex flex-col items-center w-full main-section px-8 sm:px-16">
      <div className="flex flex-col md:flex-row items-center mt-24 text-primary-100 space-y-8 max-w-[1100px] text-center gap-8 md:gap-16 lg:gap-32 xl:gap-42">
        <div
          className="w-full md:w-1/2 max-w-[450px] lg:max-w-[550px] relative bg-white/5 flex flex-col items-center px-1 md:px-8 rounded-3xl h-full m-0 bg-bottom bg-no-repeat overflow-hidden"
          style={{ backgroundImage: `url(${decor})` }}
        >
          <div className="max-w-72 md:max-w-[500px] flex flex-col items-center px-0 md:px-10 h-full w-full pt-20">
            <div className="relative flex h-full w-full items-center">
              <h1 className="text-[24px] lg:text-[32px] font-medium max-w-96">
                Pure Qur'an, Without Distractions
              </h1>
            </div>
            <div className="relative flex h-full w-full scale-140 -bottom-20 md:-bottom-10 lg:-bottom-20 -left-10 lg:-left-10 mb-12">
              <img
                src={iphone17}
                className="bottom-0 right-0"
                alt="Bayaan App showing clean, distraction-free interface"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 max-w-[512px] text-left flex flex-col gap-2 sm:gap-4 items-start">
          <span className="bg-primary-300/10 rounded-2xl px-4 py-2 text-white text-xs font-semibold">
            ALWAYS FREE
          </span>
          <h2 className="text-[28px] md:text-4xl/14 lg:text-5xl/18 font-medium">
            A Respectful Space for the Sacred Qur'an
          </h2>
          <p className="font-normal text-sm/6 md:text-base/8">
            Completely free with no advertisements or subscription fees. Experience the Qur'an in a
            clean, distraction-free environment that respects its sacred nature.
          </p>
        </div>
      </div>
    </section>
  );
}

export default SecondCTA;
