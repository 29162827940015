import Navbar from '@/component/Navbar';
import { createFileRoute } from '@tanstack/react-router';
import decor2 from '@/assets/decor2.png';
import bottom_decor from '@/assets/bottomdecor4.png';
import Footer from '@/component/Footer';
import SEO from '@/component/SEO';

export const Route = createFileRoute('/privacy')({
  component: PrivacyPolicy,
});

function PrivacyPolicy() {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="Learn about how Bayaan handles your data and protects your privacy. Read our comprehensive privacy policy for the Bayaan Qur'an streaming app."
        canonical="/privacy"
      />
      <Navbar />
      <main className="flex flex-col items-center bg-primary-900 relative text-primary-100 font-primary overflow-hidden">
        <div
          className="absolute bg-mesh pointer-events-none inset-0"
          style={{ backgroundColor: '#223d44' }}
        ></div>

        {/* Header Section with Reduced Top Margin */}
        <div
          className="w-full bg-primary-300/10 mt-4 sm:mt-6 md:mt-10 bg-bottom bg-repeat-x"
          style={{ backgroundImage: `url(${bottom_decor})` }}
        >
          <div className="w-full flex flex-col sm:flex-row items-center min-h-[250px] h-[250px] max-h-[250px]">
            <div className="flex flex-col items-center sm:items-start basis-1/2 sm:basis-full sm:ml-8 md:ml-16 lg:ml-24 xl:ml-32 2xl:ml-60 gap-3 md:gap-5 justify-end sm:justify-center text-center sm:text-left px-4 h-full">
              <span className="rounded-2xl bg-primary-300/10 px-4 py-2 text-primary-300">
                Privacy Policy
              </span>
              <h1 className="text-3xl/10 md:text-4xl/14 lg:text-5xl/18">Bayaan Privacy Policy</h1>
              <div></div>
            </div>
            <div className="basis-1/2 sm:basis-full relative h-full max-h-full w-full">
              <img
                src={decor2}
                className="absolute bottom-0 -right-22 h-full w-auto sm:w-full sm:h-auto xl:h-full xl:w-auto"
                alt=""
              />
            </div>
          </div>
        </div>

        {/* Privacy Policy Content */}
        <div className="max-w-4xl w-full px-6 md:px-10 py-12 md:py-16">
          {/* Last Updated */}
          <div className="text-primary-300 text-sm mb-8">Last Updated: March 2025</div>

          {/* Introduction */}
          <section className="mb-10">
            <p className="text-primary-200 mb-6 leading-relaxed">
              This Privacy Policy describes how Bayaan ('we', 'us', or 'our') collects, uses, and
              handles your information when you use our Qur'an streaming application ('Bayaan' or
              'the App'). We are committed to protecting your privacy and have designed our App with
              minimal data collection to respect your privacy and align with Islamic ethical
              principles.
            </p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              By downloading, installing, or using our App, you agree to the terms of this Privacy
              Policy. If you do not agree with this Privacy Policy, please do not use the App.
            </p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              For any questions about our privacy practices, you can contact us at
              privacy@thebayaan.com.
            </p>
          </section>

          {/* Information Collection */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">1. Information We Collect</h2>
            <p className="text-primary-200 mb-6 leading-relaxed">
              At Bayaan, we take a minimalist approach to data collection. Currently, we do not
              collect personal information from our users.
            </p>

            <h3 className="text-lg font-semibold mb-3">
              1.1 Information Stored Locally on Your Device
            </h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              The App stores certain information locally on your device only. This information is
              not transmitted to our servers or to third parties:
            </p>
            <ul className="list-disc pl-8 mb-4 text-primary-200 space-y-1">
              <li>Your favorite reciters and surahs</li>
              <li>'Loved' content markers</li>
              <li>App settings and preferences</li>
              <li>Playback positions and history</li>
              <li>Downloaded content for offline access</li>
            </ul>
            <p className="text-primary-200 mb-6 leading-relaxed">
              This information is stored solely for the purpose of enhancing your experience by
              remembering your preferences and enabling offline functionality. This data remains
              exclusively on your device and can be cleared through your device settings or by
              uninstalling the App.
            </p>

            <h3 className="text-lg font-semibold mb-3">1.2 Technical Information</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              The App may use certain technical processes that do not collect personal information
              but are necessary for the App to function properly.
            </p>
          </section>

          {/* How We Use Information */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">2. How We Use Information</h2>
            <p className="text-primary-200 mb-6 leading-relaxed">
              Since we currently do not collect personal information, we do not use your data for
              any purpose beyond the local functionality of the App on your device.
            </p>

            <h3 className="text-lg font-semibold mb-3">2.1 Local Device Storage</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              The information stored locally on your device is used solely to:
            </p>
            <ul className="list-disc pl-8 mb-6 text-primary-200 space-y-1">
              <li>Remember your preferences and settings</li>
              <li>Enable offline access to Qur'anic recitations</li>
              <li>Restore your previous state when you reopen the App</li>
              <li>Provide a personalized experience based on your usage patterns</li>
            </ul>
          </section>

          {/* Information Sharing */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">3. Information Sharing and Disclosure</h2>
            <p className="text-primary-200 mb-6 leading-relaxed">
              We do not share, sell, rent, or trade any information with third parties since we do
              not collect personal information from our users.
            </p>

            <h3 className="text-lg font-semibold mb-3">3.1 Third-Party Services</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              While we use Supabase for our backend infrastructure, we do not currently store any
              user-specific information there. The App connects to our backend solely to retrieve
              Qur'anic content and recitations.
            </p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              The App does not currently implement third-party analytics, tracking, or advertising
              services.
            </p>
          </section>

          {/* Data Security */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">4. Data Security</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              We are committed to ensuring the security of your data, even though we currently do
              not collect personal information.
            </p>
            <p className="text-primary-200 mb-4 leading-relaxed">
              For the information stored locally on your device, we recommend that you:
            </p>
            <ul className="list-disc pl-8 mb-6 text-primary-200 space-y-1">
              <li>Keep your device secure with appropriate authentication methods</li>
              <li>
                Keep your device's operating system and the Bayaan app updated to the latest version
              </li>
              <li>
                Be cautious when allowing others to use your device where the Bayaan app is
                installed
              </li>
            </ul>
          </section>

          {/* Children's Privacy */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">5. Children's Privacy</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              Our App is designed to be used by individuals of all ages, including children, as it
              provides access to Qur'anic recitations. The App does not knowingly collect personal
              information from anyone, including children under the age of 13.
            </p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              We are committed to complying with the Children's Online Privacy Protection Act
              (COPPA). If we learn that we have inadvertently collected personal information from a
              child under 13, we will promptly delete such information.
            </p>
          </section>

          {/* User Rights */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">6. Your Rights and Choices</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              Since we do not collect personal information, many traditional privacy rights (such as
              the right to access or delete personal data) do not currently apply to our App.
              However, you have the following rights and choices:
            </p>
            <ul className="list-disc pl-8 mb-6 text-primary-200 space-y-1">
              <li>
                You can clear locally stored data by clearing the App's cache or data through your
                device settings
              </li>
              <li>
                You can uninstall the App at any time, which will remove all locally stored
                information
              </li>
              <li>
                You can control what information is stored locally by adjusting the App's settings
              </li>
            </ul>
          </section>

          {/* International Data */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">7. International Data Transfers</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              Since we do not currently collect personal information, there are no international
              transfers of your personal data.
            </p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              If we implement features in the future that collect personal information, we will
              update this Privacy Policy and comply with applicable laws regarding international
              data transfers.
            </p>
          </section>

          {/* Policy Changes */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">8. Changes to This Privacy Policy</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              We may update this Privacy Policy from time to time to reflect changes in our
              practices or for other operational, legal, or regulatory reasons. We will notify you
              of any material changes by posting the new Privacy Policy in the App and updating the
              'Last Updated' date.
            </p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              We encourage you to review this Privacy Policy periodically for any changes. Your
              continued use of the App after the posting of changes constitutes your acceptance of
              such changes.
            </p>
          </section>

          {/* Future Data Practices */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">9. Future Data Collection Practices</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              While we currently maintain a minimal data collection approach, we may implement
              additional features in the future that would require the collection of certain
              information. Potential future data collection may include:
            </p>
            <ul className="list-disc pl-8 mb-4 text-primary-200 space-y-1">
              <li>Optional user accounts to synchronize your experience across devices</li>
              <li>Usage analytics to help us improve the App</li>
              <li>User preferences for personalization</li>
              <li>Content recommendation systems</li>
            </ul>
            <p className="text-primary-200 mb-6 leading-relaxed">
              Before implementing any new data collection practices, we will update this Privacy
              Policy and notify users accordingly. We remain committed to collecting only the
              information necessary to provide and improve our services while respecting your
              privacy.
            </p>
          </section>

          {/* Compliance */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">10. Compliance with Privacy Laws</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              Although we currently do not collect personal information, we are committed to
              compliance with applicable privacy laws and regulations, including but not limited to
              the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act
              (CCPA).
            </p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              As our services evolve, we will ensure our practices remain compliant with relevant
              privacy laws around the world.
            </p>
          </section>

          {/* Contact Info */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">11. Contact Us</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              If you have any questions, concerns, or feedback about this Privacy Policy or our
              privacy practices, please contact us at:
            </p>
            <p className="text-primary-300 font-medium mb-4">Email: privacy@thebayaan.com</p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              We welcome your questions and feedback and are committed to addressing any concerns
              you may have about your privacy.
            </p>
          </section>
        </div>

        <Footer />
      </main>
    </>
  );
}
