import logo from '@/assets/logobayaan.png';
import { useState } from 'react';
import AppStoreButton from '@/assets/appstore-badge.svg';
import GoogleplayButton from '@/assets/google-play-badge.png';
import { Link } from '@tanstack/react-router';
import { motion } from 'framer-motion';

const navigation = [
  {
    label: 'Features',
    href: '/#features',
    section: 'features',
  },
  {
    label: 'Experience',
    href: '/#experience',
    section: 'experience',
  },
  {
    label: 'Testimonials',
    href: '/#testimonial',
    section: 'testimonial',
  },
  {
    label: 'FAQ',
    href: '/#faq',
    section: 'faq',
  },
];

function MenuIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
    >
      <path
        d="M3 12H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 18H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Navbar() {
  const [IsOpen, setIsOpen] = useState(false);

  const handleNavigation = (section: string) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false);
  };

  return (
    <header className="z-[4] relative font-primary">
      <div className="fixed top-0 w-full border-b border-primary-300/10 backdrop-blur-[0.5rem]">
        <nav className="flex flex-row justify-between text-primary-100 px-8 md:px-12 xl:px-24 h-14 md:h-16 lg:h-20 items-center">
          <div className="">
            <Link to="/" preload={false}>
              <img
                src={logo}
                className="h-7 md:h-8 lg:h-10 w-auto object-contain"
                alt="Bayaan"
                loading="eager"
                fetchPriority="high"
              />
            </Link>
          </div>
          <div className="hidden lg:flex lg:gap-8 xl:gap-12 2xl:gap-16 text-primary-100">
            {navigation &&
              navigation.map((item, index) => (
                <button
                  key={index}
                  onClick={() => handleNavigation(item.section)}
                  className="text-sm xl:text-base font-medium hover:text-primary-300 transition-colors duration-200"
                >
                  {item.label}
                </button>
              ))}
          </div>
          <div className="hidden lg:flex">
            <div className="relative group">
              <motion.a
                href="https://apps.apple.com/us/app/bayaan/id6648769980"
                className="inline-block bg-amber-100 text-amber-900 font-medium px-6 py-3 rounded-full hover:bg-amber-200 transition-colors duration-200 text-sm flex items-center gap-2 whitespace-nowrap"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
              >
                <span>Download for iOS</span>
                <span className="text-xs bg-amber-900/10 px-2 py-0.5 rounded-full">
                  Android Coming Soon
                </span>
              </motion.a>
            </div>
          </div>
          <button
            className="lg:hidden flex cursor-pointer p-2 rounded-full active:bg-primary-300/20 hover:bg-primary-300/10 transition-colors duration-200 text-primary-100"
            onClick={() => setIsOpen(!IsOpen)}
            aria-label="Toggle menu"
          >
            <MenuIcon />
          </button>
        </nav>
        <SideNavigation isOpen={IsOpen} setIsOpen={setIsOpen} handleNavigation={handleNavigation} />
      </div>
    </header>
  );
}

function SideNavigation({
  isOpen,
  setIsOpen,
  handleNavigation,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNavigation: (section: string) => void;
}) {
  return (
    <div
      className={`z-10 fixed top-0 right-0 w-full h-dvh bg-primary-900 backdrop-blur-lg transform transition-transform duration-300 ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="flex flex-row justify-between items-center border-b border-primary-300/10 p-6 md:p-8">
        <Link to="/" preload={false}>
          <img
            src={logo}
            className="h-8 md:h-10 w-auto object-contain"
            alt="Bayaan"
            loading="eager"
            fetchPriority="high"
          />
        </Link>
        <button
          className="flex items-center justify-center w-10 h-10 rounded-full active:bg-primary-300/20 hover:bg-primary-300/10 transition-colors duration-200 text-primary-100"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Close menu"
        >
          <CloseIcon />
        </button>
      </div>
      <div className="flex flex-col items-center gap-4 p-6 md:p-8 md:gap-6 divide-primary-300/10 divide-y text-primary-100">
        <button
          onClick={() => handleNavigation('hero')}
          className="w-full text-center hover:text-primary-300 active:text-primary-300/70 pt-2 pb-4 md:text-lg font-medium transition-colors duration-200"
        >
          Home
        </button>
        {navigation &&
          navigation.map((item, index) => (
            <button
              key={index}
              onClick={() => handleNavigation(item.section)}
              className="w-full text-center hover:text-primary-300 active:text-primary-300/70 pt-2 pb-4 md:text-lg font-medium transition-colors duration-200"
            >
              {item.label}
            </button>
          ))}
        <div className="relative">
          <motion.a
            href="https://apps.apple.com/us/app/bayaan/id6648769980"
            className="w-full mt-4 bg-amber-100 text-amber-900 font-medium rounded-full p-4 md:p-5 hover:bg-amber-200 transition-colors duration-200 md:text-lg flex items-center justify-center gap-2"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
          >
            <span>Download for iOS</span>
            <span className="text-xs bg-amber-900/10 px-2 py-0.5 rounded-full">
              Android Coming Soon
            </span>
          </motion.a>
        </div>
      </div>
      <div className="flex flex-row gap-4 w-full items-center justify-center mt-4 md:mt-8">
        <div className="flex flex-col items-center">
          <div className="opacity-50">
            <img src={GoogleplayButton} className="h-6 md:h-8 w-auto" alt="Get it on Google Play" />
          </div>
          <span className="text-xs bg-primary-300/20 text-white px-2 py-0.5 rounded-full mt-1">
            Coming soon
          </span>
        </div>
        <motion.a
          href="https://apps.apple.com/us/app/bayaan/id6648769980"
          className="cursor-pointer"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <img src={AppStoreButton} alt="Download on App Store" className="h-6 md:h-8 w-auto" />
        </motion.a>
      </div>
    </div>
  );
}

export default Navbar;
