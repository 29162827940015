import React from 'react';
import iphone14 from '@/assets/iphone 14.png';

function FirstCTA() {
  return (
    <section
      className="flex flex-col items-center w-full main-section px-8 sm:px-16 py-16"
      id="reciters"
    >
      <div className="flex flex-col md:flex-row-reverse items-center text-primary-100 gap-12 max-w-[1100px] text-center w-full">
        <div className="w-full md:w-1/2 flex flex-col items-center h-full">
          <div className="w-full h-full flex items-center justify-center">
            <img
              src={iphone14}
              className="w-full h-full object-contain max-w-none max-h-none"
              alt="Bayaan App showing reciter selection and rewayat options"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 max-w-[512px] text-left flex flex-col gap-4 items-start font-medium">
          <span className="bg-primary-300/10 text-white rounded-2xl px-4 py-2 font-semibold text-xs">
            MULTIPLE REWAYAT
          </span>
          <h2 className="text-2xl lg:text-5xl/20">Experience the Qur'an in Different Styles</h2>
          <p className="font-normal text-sm/8 lg:text-base/8">
            Access 200+ renowned reciters and multiple authentic rewayat including Hafs, Warsh, and
            more. Each recitation style brings its own unique beauty and authenticity to the sacred
            text.
          </p>
        </div>
      </div>
    </section>
  );
}

export default FirstCTA;
