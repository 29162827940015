import React from 'react';
import bgblock from '@/assets/bg-block.png';
import audio from '@/assets/audio.png';
import give from '@/assets/give.png';
import pray from '@/assets/pray.png';
import quran from '@/assets/quran.png';

type FeatureBlockProps = {
  icon: string;
  title: string;
  description: string;
};

const features: FeatureBlockProps[] = [
  {
    title: 'Dedicated Islamic Platform',
    icon: quran,
    description:
      "A platform designed specifically for Qur'an, respecting its sacred nature with an ad-free, distraction-free environment.",
  },
  {
    title: 'Always Free',
    icon: give,
    description:
      "Completely free with no subscription fees or premium tiers, making the Qur'an accessible to everyone.",
  },
  {
    title: 'Vast Reciter Collection',
    icon: pray,
    description:
      'Access to 200+ renowned reciters from around the world, offering diverse recitation styles and authentic rewayat.',
  },
  {
    title: 'Zero Advertisements',
    icon: audio,
    description:
      'A pure, distraction-free experience with no advertisements interrupting your spiritual journey.',
  },
];

function FeaturesSection() {
  return (
    <section className="min-h-dvh flex flex-col items-center w-full main-section" id="features">
      <div className="flex flex-col items-center mt-24 text-primary-100 lg:space-y-8 max-w-[1200px] text-center w-full px-8">
        <div className="w-full grid grid-cols-1 md:grid-cols-12 items-stretch lg:gap-8">
          <div className="flex flex-col items-center md:items-start md:col-span-7 lg:gap-6">
            <span className="bg-primary-300/10 rounded-2xl px-4 py-2 text-white font-semibold">
              CORE FEATURES
            </span>
            <h1 className="text-[28px] leading-[45px] md:text-4xl/14 lg:text-[48px] font-medium md:text-left lg:leading-[72px]">
              Everything You Need for Your Qur'an Journey
            </h1>
          </div>
          <div className="flex text-sm md:text-[16px] leading-[32px] md:col-span-5 text-center md:text-right max-w-[480px] justify-self-center md:justify-self-end py-4 items-center">
            Bayaan is built on a simple promise: to provide every Muslim with a dignified,
            respectful platform for listening to the Qur'an. No ads, no fees, just pure recitations
            from trusted reciters.
          </div>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 w-full justify-around md:mt-5">
          {features &&
            features.map((feature, index) => (
              <FeatureBlock
                title={feature.title}
                icon={feature.icon}
                description={feature.description}
                key={index}
              />
            ))}
        </div>
      </div>
    </section>
  );
}

function FeatureBlock(props: FeatureBlockProps) {
  return (
    <div className="min-w-[120px] md:max-w-[292px] relative bg-white/5 flex flex-col items-center bg-bottom justify-self-center px-1 pb-20 cursor-pointer transform transition-transform duration-200 hover:scale-105 h-full">
      <div
        className="absolute bottom-0 left-0 w-full h-[7dvh] pointer-events-none bg-repeat-x bg-bottom"
        style={{ backgroundImage: `url(${bgblock})` }}
      ></div>
      <div className="w-full pt-8 flex flex-col flex-grow gap-4 text-center max-w-64 items-center">
        <img
          src={props.icon}
          className="min-w-12 w-4/9 md:max-w-[100px]"
          alt={`${props.title} icon`}
        />
        <h2 className="text-sm min-[580px]:text-base md:text-lg xl:text-2xl">{props.title}</h2>
        <p className="text-xs min-[580px]:text-sm xl:text-base opacity-50">{props.description}</p>
      </div>
    </div>
  );
}

export default FeaturesSection;
