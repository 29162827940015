import React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import plus from '@/assets/plus.svg';
import minus from '@/assets/minus.svg';

type AccordionProps = {
  title: string;
  description: string;
  isOpen: boolean;
  onClick: () => void;
};

function Accordion({ title, description, isOpen, onClick }: AccordionProps) {
  return (
    <AccordionPrimitive.Root
      type="single"
      value={isOpen ? 'item-1' : ''}
      onValueChange={() => onClick()}
      className="min-w-[300px] md:min-w-[400px] lg:min-w-[480px]"
    >
      <AccordionPrimitive.Item value="item-1" className="rounded-md overflow-hidden">
        <AccordionPrimitive.Header className="flex">
          <AccordionPrimitive.Trigger
            className={`flex flex-1 items-center justify-between p-4 cursor-pointer transition-colors duration-200 ${
              isOpen ? 'bg-white/10' : ''
            }`}
          >
            <h3 className="text-sm md:text-base lg:text-xl font-normal text-left">{title}</h3>
            <img
              src={isOpen ? minus : plus}
              className={`w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 transition-transform duration-200 ${
                isOpen ? 'rotate-180' : ''
              }`}
              alt={isOpen ? 'Close' : 'Open'}
              aria-hidden="true"
            />
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
        <AccordionPrimitive.Content className="overflow-hidden data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp">
          <div className="p-4 pt-0">
            <p className="text-gray-500 text-xs md:text-sm lg:text-base">{description}</p>
          </div>
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  );
}

export type { AccordionProps };

export default Accordion;
