import React, { useState, useMemo, useRef } from 'react';
import { testimonials, TestimonialProps } from '@/data/testimonials';

const ITEMS_PER_PAGE = 4;

function TestimonialSection() {
  const [selectedFeature, setSelectedFeature] = useState<string | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Get unique features from testimonials
  const features = useMemo(() => Array.from(new Set(testimonials.map(t => t.feature))), []);

  // Filter testimonials based on selected feature
  const filteredTestimonials = useMemo(
    () =>
      selectedFeature ? testimonials.filter(t => t.feature === selectedFeature) : testimonials,
    [selectedFeature]
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredTestimonials.length / ITEMS_PER_PAGE);

  // Scroll functions
  const scrollToPage = (pageIndex: number) => {
    if (!scrollContainerRef.current) return;
    const containerWidth = scrollContainerRef.current.offsetWidth;
    scrollContainerRef.current.scrollTo({
      left: containerWidth * pageIndex,
      behavior: 'smooth',
    });
    setActiveIndex(pageIndex);
  };

  const scrollNext = () => {
    if (activeIndex < totalPages - 1) {
      scrollToPage(activeIndex + 1);
    }
  };

  const scrollPrev = () => {
    if (activeIndex > 0) {
      scrollToPage(activeIndex - 1);
    }
  };

  // Reset scroll position when feature changes
  React.useEffect(() => {
    setActiveIndex(0);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 0;
    }
  }, [selectedFeature]);

  return (
    <section
      className="flex flex-col items-center w-full main-section px-8 py-16 overflow-hidden"
      id="testimonial"
    >
      <div className="flex flex-col items-center text-primary-100 space-y-8 max-w-[1200px] text-center">
        <div className="max-w-[600px] flex flex-col items-center gap-4">
          <span className="rounded-3xl bg-primary-300/10 px-8 py-3 text-white text-xs font-semibold">
            TESTIMONIALS
          </span>
          <h2 className="text-[28px] md:text-4xl/14 lg:text-5xl/18">Loved by Muslims Worldwide</h2>
          <p className="font-normal text-sm/6 sm:text-base/8">
            See how Bayaan is helping Muslims connect with the Qur'an through authentic experiences
            from our global community
          </p>
        </div>

        <div className="flex flex-wrap justify-center gap-2 mb-8">
          {features.map(feature => (
            <button
              key={feature}
              onClick={() => setSelectedFeature(selectedFeature === feature ? null : feature)}
              className={`px-4 py-2 rounded-full text-xs md:text-sm transition-colors duration-200 ${
                selectedFeature === feature
                  ? 'bg-primary-300 text-primary-900'
                  : 'bg-white/10 text-white hover:bg-white/20'
              }`}
            >
              {feature}
            </button>
          ))}
        </div>

        {/* Scrollable Container */}
        <div className="relative w-full">
          {/* Navigation Arrows */}
          <button
            onClick={scrollPrev}
            disabled={activeIndex === 0}
            className={`absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 p-2 rounded-full transition-all duration-200 ${
              activeIndex === 0
                ? 'bg-white/5 text-white/30 cursor-not-allowed'
                : 'bg-white/10 text-white hover:bg-white/20'
            }`}
            aria-label="Previous testimonials"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          <div
            ref={scrollContainerRef}
            className="flex overflow-x-hidden overflow-y-hidden scroll-smooth snap-x snap-mandatory gap-6 w-full"
          >
            {Array.from({ length: totalPages }).map((_, pageIndex) => (
              <div key={pageIndex} className="flex-none w-full snap-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {filteredTestimonials
                    .slice(pageIndex * ITEMS_PER_PAGE, (pageIndex + 1) * ITEMS_PER_PAGE)
                    .map((testimonial, index) => (
                      <TestimonialBlock
                        key={`${testimonial.name}-${index}`}
                        {...testimonial}
                        isActive={pageIndex === activeIndex}
                        onClick={() => scrollToPage(pageIndex)}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>

          <button
            onClick={scrollNext}
            disabled={activeIndex === totalPages - 1}
            className={`absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 p-2 rounded-full transition-all duration-200 ${
              activeIndex === totalPages - 1
                ? 'bg-white/5 text-white/30 cursor-not-allowed'
                : 'bg-white/10 text-white hover:bg-white/20'
            }`}
            aria-label="Next testimonials"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        {/* Pagination Dots */}
        <div className="flex items-center gap-4 mt-8">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => scrollToPage(index)}
              className={`w-2 h-2 rounded-full transition-all duration-200 ${
                index === activeIndex ? 'bg-primary-300 w-4' : 'bg-white/20'
              }`}
              aria-label={`Go to testimonial page ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

function TestimonialBlock(props: TestimonialProps & { isActive: boolean; onClick: () => void }) {
  return (
    <div
      className={`bg-white/10 rounded-3xl flex flex-col justify-between p-6 md:p-8 text-left gap-4 h-[280px] max-w-[530px] transition-all duration-300 cursor-pointer ${
        props.isActive ? 'bg-white/15 scale-[1.02]' : 'hover:bg-white/15'
      }`}
      onClick={props.onClick}
    >
      <div className="flex items-start gap-2">
        {[...Array(5)].map((_, i) => (
          <svg
            key={i}
            className={`w-4 h-4 ${i < props.rating ? 'text-primary-300' : 'text-white/20'}`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.363 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118l-2.8-2.034c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        ))}
      </div>

      <p className="font-normal italic text-sm/6 sm:text-base/8 flex-grow line-clamp-4">
        "{props.comment}"
      </p>

      <div className="flex flex-row gap-4 items-center">
        <img
          src={props.avatar}
          alt={`${props.name}'s profile`}
          className="w-12 h-12 sm:w-16 sm:h-16 rounded-full self-start"
        />
        <div className="flex flex-col">
          <p className="font-bold text-sm sm:text-base">{props.name}</p>
          <p className="font-normal text-sm sm:text-base text-white/80">{props.role}</p>
          <p className="font-normal text-xs sm:text-sm text-white/60">{props.location}</p>
        </div>
      </div>
    </div>
  );
}

export default TestimonialSection;
