import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';

// Import the generated route tree
import { routeTree } from './routeTree.gen';

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Check if we're in a browser environment
const isBrowser = typeof window !== 'undefined';

// Render the app
if (isBrowser) {
  const rootElement = document.getElementById('root')!;
  rootElement.classList.add('overscroll-none');

  // Only render if the element is empty (client-side)
  if (rootElement.innerHTML === '') {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <StrictMode>
        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      </StrictMode>
    );
  }
}

// Export for SSG
export function render() {
  return (
    <StrictMode>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </StrictMode>
  );
}
