import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  canonical?: string;
  ogImage?: string;
  ogType?: 'website' | 'article';
  twitterCard?: 'summary' | 'summary_large_image';
}

export default function SEO({
  title = 'Bayaan - Your Islamic Companion App',
  description = 'Bayaan provides a serene, completely free environment for listening to Qur\'anic recitations. Built with careful attention to Islamic principles, our app offers high-quality audio from respected reciters worldwide - with no advertisements or interruptions.',
  canonical = 'https://bayaan.app',
  ogImage = '/assets/splash.png',
  ogType = 'website',
  twitterCard = 'summary_large_image',
}: SEOProps) {
  const siteUrl = 'https://bayaan.app';
  const fullTitle = title.includes('Bayaan') ? title : `${title} | Bayaan`;

  return (
    <Helmet>
      {/* Basic metadata */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <link
        rel="canonical"
        href={canonical.startsWith('http') ? canonical : `${siteUrl}${canonical}`}
      />

      {/* Open Graph */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={ogType} />
      <meta
        property="og:url"
        content={canonical.startsWith('http') ? canonical : `${siteUrl}${canonical}`}
      />
      <meta
        property="og:image"
        content={ogImage.startsWith('http') ? ogImage : `${siteUrl}${ogImage}`}
      />
      <meta property="og:site_name" content="Bayaan" />

      {/* Twitter Card */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={ogImage.startsWith('http') ? ogImage : `${siteUrl}${ogImage}`}
      />

      {/* Additional metadata */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#000000" />
    </Helmet>
  );
}
