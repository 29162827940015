import React from 'react';
import AppStoreButton from '@/assets/appstore-badge.svg';
import GoogleplayButton from '@/assets/google-play-badge.png';
import BottomDecoration from '@/assets/decor3.png';
import Iphone18 from '@/assets/iphone18.png';
import { motion } from 'framer-motion';

function InfoSection() {
  return (
    <section className="flex flex-col items-center w-full main-section p-12 md:p-16">
      <div className="flex flex-col md:flex-row items-center mt-16 md:mt-20 text-primary-100 space-y-8 max-w-[1250px] text-center py-10 md:py-14 border border-primary-300/20 rounded-2xl bg-gradient-to-br from-primary-900/50 to-primary-800/50 backdrop-blur-sm overflow-hidden gap-8 relative min-h-[650px] md:min-h-[650px]">
        <div
          className="absolute -bottom-15 left-0 w-full h-[7dvh] pointer-events-none bg-repeat-x"
          style={{
            backgroundImage: `url(${BottomDecoration})`,
          }}
        ></div>
        <div
          className="absolute top-0 left-0 w-full h-[7dvh] pointer-events-none bg-repeat-x"
          style={{
            backgroundImage: `url(${BottomDecoration})`,
          }}
        ></div>
        <div className="w-full md:w-1/2 text-left flex flex-1 flex-col gap-6 items-start px-8 relative z-10">
          <div className="flex flex-col gap-4">
            <div className="flex gap-2">
              <span className="bg-primary-300/10 text-white text-xs font-semibold rounded-2xl px-4 py-2">
                FREE FOREVER
              </span>
              <span className="bg-primary-300/10 text-white text-xs font-semibold rounded-2xl px-4 py-2">
                NO ADS
              </span>
            </div>
            <h2 className="text-2xl md:text-5xl/14">Your Digital Hub for the Qur'an</h2>
            <p className="font-normal text-sm/6 md:text-base/8 text-primary-300">
              Experience the Qur'an as it deserves to be heard
            </p>
          </div>
          <ul className="flex flex-col gap-3 text-sm md:text-base">
            <li className="flex items-center gap-2">
              <span className="w-1.5 h-1.5 rounded-full bg-primary-300"></span>
              200+ Renowned Reciters
            </li>
            <li className="flex items-center gap-2">
              <span className="w-1.5 h-1.5 rounded-full bg-primary-300"></span>
              Multiple Authentic Rewayat
            </li>
            <li className="flex items-center gap-2">
              <span className="w-1.5 h-1.5 rounded-full bg-primary-300"></span>
              Offline Access
            </li>
            <li className="flex items-center gap-2">
              <span className="w-1.5 h-1.5 rounded-full bg-primary-300"></span>
              No Advertisements
            </li>
          </ul>
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4 items-start mb-1">
              <div className="flex flex-col items-center">
                <motion.a
                  href="https://apps.apple.com/us/app/bayaan/id6648769980"
                  className="cursor-pointer"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                >
                  <img src={AppStoreButton} alt="Download on App Store" className="h-10 w-auto" />
                </motion.a>
                <span className="text-xs invisible px-2 py-0.5 rounded-full mt-1">&nbsp;</span>
              </div>
              <div className="flex flex-col items-center">
                <div className="opacity-50">
                  <img src={GoogleplayButton} className="h-10 w-auto" alt="Get it on Google Play" />
                </div>
                <span className="text-xs bg-primary-300/20 text-white px-2 py-0.5 rounded-full mt-1">
                  Coming soon
                </span>
              </div>
            </div>
            <p className="text-xs text-primary-300 mt-0">
              Join thousands of Muslims worldwide in their spiritual journey
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex flex-1 items-center justify-center pb-8 md:pb-0 z-2 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="relative"
          >
            <div className="absolute inset-0 bg-primary-300/10 rounded-full blur-2xl"></div>
            <img
              src={Iphone18}
              className="w-full h-auto object-contain relative scale-70 md:scale-100 top-8 md:top-8 xl:top-0"
              alt="Bayaan App Interface"
              loading="lazy"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default InfoSection;
