import React, { useMemo, useCallback } from 'react';
import Accordion from './Accordion';

type FAQCategory = {
  name: string;
  description: string;
  questions: {
    title: string;
    description: string;
  }[];
};

const faqCategories: FAQCategory[] = [
  {
    name: 'Getting Started',
    description: 'Essential information to help you begin your journey with Bayaan',
    questions: [
      {
        title: 'What is Bayaan?',
        description:
          "Bayaan is a dedicated Islamic platform for listening to the Qur'an, featuring 200+ renowned reciters, multiple authentic rewayat, and a distraction-free environment. Unlike general streaming services, Bayaan respects the sacred nature of the Qur'an by offering an ad-free, dedicated platform specifically for Islamic audio content.",
      },
      {
        title: 'Is Bayaan completely free?',
        description:
          "Yes, Bayaan is completely free with no subscription fees, in-app purchases, or premium tiers. We believe in making the Qur'an accessible to everyone without any financial barriers.",
      },
      {
        title: 'Does Bayaan have advertisements?',
        description:
          "No, Bayaan is completely ad-free. We maintain a pure, distraction-free environment to respect the sacred nature of the Qur'an and ensure an uninterrupted spiritual experience.",
      },
    ],
  },
  {
    name: 'Features & Functionality',
    description: "Learn about Bayaan's powerful features and capabilities",
    questions: [
      {
        title: 'What reciters are available on Bayaan?',
        description:
          'Bayaan features 200+ renowned reciters from around the world, offering diverse recitation styles and authentic rewayat. Our collection includes some of the most respected voices in the Muslim world, with new reciters added regularly.',
      },
      {
        title: 'What rewayat are supported?',
        description:
          "Bayaan supports multiple authentic Qur'anic transmissions (Hafs, Warsh, Qalun, etc.). Each rewayah is clearly labeled and includes educational information about different recitation styles. You can easily filter and switch between different rewayat based on your preference.",
      },
      {
        title: 'Can I follow along with the text while listening?',
        description:
          'Yes, Bayaan offers a synchronized text display feature that follows the recitation. You can view the Arabic text with proper uthmani script, along with optional translations in multiple languages. The text auto-scrolls as the recitation progresses.',
      },
    ],
  },
  {
    name: 'Technical & Offline Access',
    description: 'Information about technical requirements and offline capabilities',
    questions: [
      {
        title: 'Does Bayaan work offline?',
        description:
          'Yes, Bayaan supports offline access. You can download entire surahs for offline listening, perfect for travel or areas with limited connectivity. The app includes a download queue system and storage management options.',
      },
      {
        title: 'What devices are supported?',
        description:
          'Bayaan is available on iOS (iPhone and iPad) and Android (phones and tablets). The app is optimized for various screen sizes and connection speeds.',
      },
      {
        title: 'How do I manage my downloaded content?',
        description:
          "You can easily manage your downloaded content through the app's library section. Here you can view all downloaded surahs, manage storage, and control download quality settings.",
      },
    ],
  },
  {
    name: 'Privacy & Security',
    description: 'Learn about our commitment to privacy',
    questions: [
      {
        title: 'How does Bayaan handle privacy?',
        description:
          "Bayaan is committed to maintaining your privacy. We don't collect any personal information or usage data. The app works completely offline once you've downloaded your preferred content, ensuring your listening experience remains private and secure.",
      },
      {
        title: 'Is my downloaded content private?',
        description:
          "Yes, all your downloaded content is stored locally on your device only. We don't have access to your downloads or any other information about your usage of the app.",
      },
    ],
  },
];

// Memoized FAQ item component
const FAQItem = React.memo(
  ({
    title,
    description,
    isOpen,
    onClick,
  }: {
    title: string;
    description: string;
    isOpen: boolean;
    onClick: () => void;
  }) => <Accordion title={title} description={description} isOpen={isOpen} onClick={onClick} />
);

// Memoized category button component
const CategoryButton = React.memo(
  ({ name, isSelected, onClick }: { name: string; isSelected: boolean; onClick: () => void }) => (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded-full text-xs md:text-sm transition-colors duration-200 ${
        isSelected ? 'bg-primary-300 text-primary-900' : 'bg-white/10 text-white hover:bg-white/20'
      }`}
    >
      {name}
    </button>
  )
);

function FAQSection() {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(null);

  // Memoize the flattened questions array
  const allQuestions = useMemo(
    () =>
      faqCategories.flatMap(category =>
        category.questions.map(q => ({
          ...q,
          category: category.name,
        }))
      ),
    []
  );

  // Memoize filtered questions
  const filteredQuestions = useMemo(
    () =>
      selectedCategory ? allQuestions.filter(q => q.category === selectedCategory) : allQuestions,
    [selectedCategory, allQuestions]
  );

  // Memoize category selection handler
  const handleCategorySelect = useCallback((category: string) => {
    setSelectedCategory(prev => (prev === category ? null : category));
  }, []);

  // Memoize accordion toggle handler
  const handleAccordionToggle = useCallback((index: number) => {
    setOpenIndex(prev => (prev === index ? null : index));
  }, []);

  return (
    <section
      className="flex flex-col items-center w-full main-section p-8 md:p-10 lg:p-12"
      id="faq"
    >
      <div className="flex flex-col lg:flex-row items-center mt-5 text-primary-100 space-y-8 max-w-[1200px] text-center gap-8">
        <div className="w-full lg:w-1/2 max-w-[512px] text-center lg:text-left flex flex-col gap-4 items-center lg:items-start">
          <span className="bg-primary-300/10 rounded-3xl px-6 py-3 text-xs md:text-sm lg:text-base font-semibold text-white">
            FAQ
          </span>
          <h2 className="text-[24px] md:text-3xl lg:text-5xl/20 font-normal">
            Frequently Asked Questions
          </h2>
          <p className="font-normal text-sm/6 md:text-base/8">
            Find answers to common questions about Bayaan's features, functionality, and commitment
            to providing a dignified platform for the Qur'an.
          </p>
          <div className="flex flex-wrap gap-2">
            {faqCategories.map(category => (
              <CategoryButton
                key={category.name}
                name={category.name}
                isSelected={selectedCategory === category.name}
                onClick={() => handleCategorySelect(category.name)}
              />
            ))}
          </div>
          <a
            href="/support"
            className="bg-primary-300 text-primary-900 rounded-full px-5 py-2 text-sm md:text-base font-medium hover:bg-primary-300/90 transition-colors duration-200 mt-2"
          >
            Still have questions? Contact us
          </a>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          {filteredQuestions.map((item, index) => (
            <FAQItem
              key={`${item.category}-${index}`}
              title={item.title}
              description={item.description}
              isOpen={openIndex === index}
              onClick={() => handleAccordionToggle(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQSection;
