import Navbar from '@/component/Navbar';
import { createFileRoute } from '@tanstack/react-router';
import decor2 from '@/assets/decor2.png';
import bottom_decor from '@/assets/bottomdecor4.png';
import Footer from '@/component/Footer';
import SEO from '@/component/SEO';

export const Route = createFileRoute('/terms')({
  component: TermsAndConditions,
});

function TermsAndConditions() {
  return (
    <>
      <SEO
        title="Terms and Conditions"
        description="Read the terms and conditions for using the Bayaan Qur'an streaming app. Learn about our policies, user agreements, and legal information."
        canonical="/terms"
      />
      <Navbar />
      <main className="flex flex-col items-center bg-primary-900 relative text-primary-100 font-primary overflow-hidden">
        <div
          className="absolute bg-mesh pointer-events-none inset-0"
          style={{ backgroundColor: '#223d44' }}
        ></div>

        {/* Header Section with Reduced Top Margin */}
        <div
          className="w-full bg-primary-300/10 mt-4 sm:mt-6 md:mt-10 bg-bottom bg-repeat-x"
          style={{ backgroundImage: `url(${bottom_decor})` }}
        >
          <div className="w-full flex flex-col sm:flex-row items-center min-h-[250px] h-[250px] max-h-[250px]">
            <div className="flex flex-col items-center sm:items-start basis-1/2 sm:basis-full sm:ml-8 md:ml-16 lg:ml-24 xl:ml-32 2xl:ml-60 gap-3 md:gap-5 justify-end sm:justify-center text-center sm:text-left px-4 h-full">
              <span className="rounded-2xl bg-primary-300/10 px-4 py-2 text-primary-300">
                Terms and Conditions
              </span>
              <h1 className="text-3xl/10 md:text-4xl/14 lg:text-5xl/18">Bayaan Terms of Service</h1>
              <div></div>
            </div>
            <div className="basis-1/2 sm:basis-full relative h-full max-h-full w-full">
              <img
                src={decor2}
                className="absolute bottom-0 -right-22 h-full w-auto sm:w-full sm:h-auto xl:h-full xl:w-auto"
                alt=""
              />
            </div>
          </div>
        </div>

        {/* Terms and Conditions Content */}
        <div className="max-w-4xl w-full px-6 md:px-10 py-12 md:py-16">
          {/* Last Updated */}
          <div className="text-primary-300 text-sm mb-8">Last Updated: March 2025</div>

          {/* Introduction */}
          <section className="mb-10">
            <p className="text-primary-200 mb-6 leading-relaxed">
              Welcome to Bayaan, a free Qur'an streaming application featuring 200+ reciters and
              multiple rewayat. These Terms of Service ("Terms") govern your use of the Bayaan
              application ("App") provided by Bayaan ("we", "us", or "our"). By downloading,
              installing, or using our App, you agree to be bound by these Terms. If you do not
              agree with these Terms, please do not use the App.
            </p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              For any questions about these Terms, you can contact us at legal@thebayaan.com.
            </p>
          </section>

          {/* Content Ownership & Licensing */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">1. Content Ownership & Licensing</h2>

            <h3 className="text-lg font-semibold mb-3">1.1 External Content Sources</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              Most Qur'anic audio content is sourced from MP3Quran.com through their public APIs. By
              using our App, you acknowledge that this content is provided by MP3Quran.com and is
              subject to their terms of service. We provide appropriate attribution to MP3Quran.com
              as the source of this content.
            </p>

            <h3 className="text-lg font-semibold mb-3">1.2 Exclusive Content</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              Some exclusive recitations within the App are owned by the reciters themselves. Bayaan
              does not own this content but has permission to distribute it. These include live
              Taraweeh recordings and other exclusive recitations. While we ensure all reciters are
              legitimate (with ijaza or vouched for by respected sheikhs), we cannot guarantee
              absolute perfection in recitation as human error is possible. Allah alone is perfect.
            </p>

            <h3 className="text-lg font-semibold mb-3">1.3 Textual Content</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              Qur'anic text content is retrieved from Quran.com or Qul.Tarteel.ai. We provide
              appropriate attribution to these sources. While these platforms are managed by
              legitimate companies dedicated to accuracy, we encourage users to verify any critical
              textual information with printed Mushaf editions.
            </p>
          </section>

          {/* User Permissions & Restrictions */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">2. User Permissions & Restrictions</h2>

            <h3 className="text-lg font-semibold mb-3">2.1 Permitted Use</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              You are permitted to use the App for normal day-to-day usage, which includes:
            </p>
            <ul className="list-disc pl-8 mb-6 text-primary-200 space-y-1">
              <li>Personal, non-commercial use</li>
              <li>Listening to recitations</li>
              <li>Downloading content for offline personal use</li>
              <li>Sharing links to content within the App</li>
            </ul>

            <h3 className="text-lg font-semibold mb-3">2.2 Prohibited Use</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">You are prohibited from:</p>
            <ul className="list-disc pl-8 mb-6 text-primary-200 space-y-1">
              <li>Reverse engineering, decompiling, or disassembling the App</li>
              <li>Attempting to derive the source code</li>
              <li>Removing copyright notices or attributions</li>
              <li>Using automated systems to access or download content</li>
              <li>Redistributing, selling, or licensing content</li>
              <li>Using the App for any illegal purposes</li>
              <li>Circumventing security measures</li>
            </ul>

            <h3 className="text-lg font-semibold mb-3">2.3 Content Sharing</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              You may share links to content within the App and reference recitations for
              educational or religious purposes. However, you may not redistribute the audio files
              themselves commercially or claim ownership of the content.
            </p>
          </section>

          {/* Technical Terms */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">3. Technical Terms</h2>

            <h3 className="text-lg font-semibold mb-3">3.1 Service Availability</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              While we strive to ensure continuous availability of our App, we do not guarantee
              uninterrupted access. The service may experience interruptions due to maintenance,
              updates, or technical issues. We provide the service on a best-efforts basis.
            </p>

            <h3 className="text-lg font-semibold mb-3">3.2 App Updates</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              Users may be required to update the App to continue using the service. Older versions
              may eventually become unsupported. Updates may be necessary for security,
              functionality, or content access. We recommend keeping the App updated to the latest
              version.
            </p>

            <h3 className="text-lg font-semibold mb-3">3.3 Device Compatibility</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              The App is designed to work on standard iOS and Android devices. Not all features may
              be available on all devices. Minimum operating system requirements may change over
              time. The App follows Apple App Store and Google Play Store standards for
              compatibility.
            </p>

            <h3 className="text-lg font-semibold mb-3">3.4 Offline Content</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              Downloaded content is for personal use only. There are no specific restrictions on how
              long content remains available offline. However, downloaded content should not be
              extracted from the App or redistributed in any form.
            </p>
          </section>

          {/* User Interactions */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">4. User Interactions</h2>

            <h3 className="text-lg font-semibold mb-3">4.1 User Feedback & Submissions</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              When you submit feedback, bug reports, or feature requests, you grant Bayaan a
              royalty-free, perpetual, irrevocable right to use, reproduce, modify, and incorporate
              the ideas. You confirm that you have the right to share such feedback. No compensation
              will be provided for implemented suggestions, and Bayaan has no obligation to
              implement any suggestions.
            </p>

            <h3 className="text-lg font-semibold mb-3">4.2 User Communications</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              Bayaan may send notifications related to service updates, new features, or content.
              Users can opt out of non-essential communications through app settings or by
              contacting us. Essential communications about service changes or updates to these
              Terms may still be sent regardless of notification preferences.
            </p>
          </section>

          {/* Legal Protections */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">5. Legal Protections</h2>

            <h3 className="text-lg font-semibold mb-3">5.1 Disclaimers & Warranties</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              The App is provided "as is" without warranties of any kind, either express or implied.
              We make no guarantees regarding the accuracy of Qur'anic text or recitations.
              Recitations by exclusive reciters are provided by legitimate reciters with proper
              credentials, but human error is possible. Allah alone is perfect, and any errors in
              recitation are human in nature.
            </p>

            <h3 className="text-lg font-semibold mb-3">5.2 Liability Limitations</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              To the maximum extent permitted by applicable law, Bayaan shall not be liable for any
              indirect, incidental, special, or consequential damages resulting from the use or
              inability to use the App. We are not liable for third-party content or links, or for
              issues arising from internet connectivity or device functionality.
            </p>

            <h3 className="text-lg font-semibold mb-3">5.3 Indemnification</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              You agree to defend, indemnify, and hold harmless Bayaan from claims arising from your
              violation of these Terms, laws, or third-party rights. In simpler terms, you are
              responsible for your own actions when using the App.
            </p>
          </section>

          {/* Administrative Terms */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">6. Administrative Terms</h2>

            <h3 className="text-lg font-semibold mb-3">6.1 Modifications to Terms</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              These Terms may be updated periodically. Users will be notified of significant changes
              via in-app notification. Continued use after changes constitutes acceptance of the
              updated Terms. The date of the last update will be included at the top of the Terms.
            </p>

            <h3 className="text-lg font-semibold mb-3">6.2 Termination</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              You may terminate your use of the App by deleting it from your device. Bayaan reserves
              the right to terminate access for violations of these Terms. Upon termination,
              downloaded content may no longer be accessible through the App.
            </p>

            <h3 className="text-lg font-semibold mb-3">6.3 Governing Law</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              These Terms shall be governed by and construed in accordance with the laws of the
              jurisdiction where Bayaan is registered, without regard to its conflict of law
              provisions.
            </p>

            <h3 className="text-lg font-semibold mb-3">6.4 Severability</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              If any provision of these Terms is found to be invalid or unenforceable, the remaining
              provisions will remain in full force and effect.
            </p>
          </section>

          {/* Special Considerations */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">7. Special Considerations</h2>

            <h3 className="text-lg font-semibold mb-3">7.1 Religious Content Respect</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              The App contains sacred Qur'anic content that must be treated with respect. Users are
              prohibited from misusing this religious material or using it in a disrespectful
              manner. By using the App, you acknowledge the sacred nature of the content and agree
              to treat it accordingly.
            </p>

            <h3 className="text-lg font-semibold mb-3">7.2 Age Considerations</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              There are no minimum age restrictions for the App, as it provides access to religious
              content suitable for all ages. However, parental guidance is recommended for younger
              users. We comply with the Children's Online Privacy Protection Act (COPPA) for users
              under 13.
            </p>

            <h3 className="text-lg font-semibold mb-3">7.3 Future Paid Features</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              While the App is currently free, we may introduce premium features in the future. If
              premium features are introduced, we will provide clear information about payment
              terms, cancellation, and refund policies. Please note that core Qur'anic content will
              always remain free.
            </p>

            <h3 className="text-lg font-semibold mb-3">7.4 Third-Party Links & Services</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              The App contains links to third parties including credits to designers, development
              libraries, Qur'anic text/info libraries, and audio libraries. Bayaan is not
              responsible for third-party content. Users access third-party content at their own
              risk. Third-party terms may apply when accessing their services.
            </p>
          </section>

          {/* App Store Compliance */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">8. App Store Compliance</h2>

            <h3 className="text-lg font-semibold mb-3">8.1 Apple App Store</h3>
            <p className="text-primary-200 mb-4 leading-relaxed">
              For users who download the App from the Apple App Store, you acknowledge that Apple
              has no obligation for maintenance or support of the App. Apple is not responsible for
              addressing any claims related to the App. Apple is a third-party beneficiary of these
              Terms.
            </p>

            <h3 className="text-lg font-semibold mb-3">8.2 Google Play Store</h3>
            <p className="text-primary-200 mb-6 leading-relaxed">
              For users who download the App from the Google Play Store, the App complies with the
              Google Play Developer Distribution Agreement. You acknowledge that Google is not a
              party to these Terms.
            </p>
          </section>

          {/* Contact Information */}
          <section className="mb-10">
            <h2 className="text-xl font-bold mb-4">9. Contact Information</h2>
            <p className="text-primary-200 mb-4 leading-relaxed">
              If you have any questions, concerns, or feedback about these Terms, please contact us
              at:
            </p>
            <p className="text-primary-300 font-medium mb-4">Email: legal@thebayaan.com</p>
            <p className="text-primary-200 mb-6 leading-relaxed">
              We welcome your questions and are committed to addressing any concerns you may have
              about these Terms.
            </p>
          </section>
        </div>

        <Footer />
      </main>
    </>
  );
}
