/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as TermsImport } from './routes/terms';
import { Route as PrivacyImport } from './routes/privacy';
import { Route as IndexImport } from './routes/index';

// Create/Update Routes

const TermsRoute = TermsImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => rootRoute,
} as any);

const PrivacyRoute = PrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/privacy': {
      id: '/privacy';
      path: '/privacy';
      fullPath: '/privacy';
      preLoaderRoute: typeof PrivacyImport;
      parentRoute: typeof rootRoute;
    };
    '/terms': {
      id: '/terms';
      path: '/terms';
      fullPath: '/terms';
      preLoaderRoute: typeof TermsImport;
      parentRoute: typeof rootRoute;
    };
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/privacy': typeof PrivacyRoute;
  '/terms': typeof TermsRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/privacy': typeof PrivacyRoute;
  '/terms': typeof TermsRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/privacy': typeof PrivacyRoute;
  '/terms': typeof TermsRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths: '/' | '/privacy' | '/terms';
  fileRoutesByTo: FileRoutesByTo;
  to: '/' | '/privacy' | '/terms';
  id: '__root__' | '/' | '/privacy' | '/terms';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  PrivacyRoute: typeof PrivacyRoute;
  TermsRoute: typeof TermsRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  PrivacyRoute: PrivacyRoute,
  TermsRoute: TermsRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/privacy",
        "/terms"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/privacy": {
      "filePath": "privacy.tsx"
    },
    "/terms": {
      "filePath": "terms.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
