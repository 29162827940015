import React from 'react';
import logo from '@/assets/logobayaan.png';
import tiktok from '@/assets/social-4.png';
import linkedin from '@/assets/social-5.png';
import facebook from '@/assets/social-6.png';
import twitter from '@/assets/social-7.png';
import instagram from '@/assets/social-8.png';
import youtube from '@/assets/social-9.png';

function Footer() {
  const handleNavigation = (section: string) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="flex flex-col items-center w-full main-section pb-24">
      <div className="flex flex-col items-center mt-24 text-primary-100 space-y-8 w-full text-center max-w-[1400px] px-12 sm:px-16 md:px-24">
        <div className="flex items-start w-full pt-8 ">
          <span>
            <img src={logo} className="h-12" alt="" />
          </span>
        </div>
        <div className="flex flex-col md:flex-row w-full gap-12 justify-between">
          <div className="flex flex-row gap-4 items-start w-full text-left">
            <a href="https://instagram.com/bayaanapp">
              <img
                src={instagram}
                className="hover:scale-110 transition-transform duration-200"
                alt=""
              />
            </a>
            <a href="https://www.tiktok.com/@bayaan.app">
              <img
                src={tiktok}
                className="hover:scale-110 transition-transform duration-200"
                alt=""
              />
            </a>
            <a href="https://x.com/bayaanapp">
              <img
                src={twitter}
                className="hover:scale-110 transition-transform duration-200"
                alt=""
              />
            </a>
            <a href="https://www.youtube.com/@thebayaan">
              <img
                src={youtube}
                className="hover:scale-110 transition-transform duration-200"
                alt=""
              />
            </a>
            <a href="https://www.facebook.com/people/Bayaan/61573690642505/">
              <img
                src={facebook}
                className="hover:scale-110 transition-transform duration-200"
                alt=""
              />
            </a>
            <a href="http://linkedin.com/company/bayaanapp">
              <img
                src={linkedin}
                className="hover:scale-110 transition-transform duration-200"
                alt=""
              />
            </a>
          </div>
          <div className="flex flex-col md:flex-row md:ml-0 lg:ml-24 gap-8 md:gap-12 lg:gap-24 text-left text-base lg:text-xl">
            <div className="flex flex-col items-start gap-2 md:gap-6">
              <button
                onClick={() => handleNavigation('hero')}
                className="font-bold hover:text-primary-300 transition-colors duration-200"
              >
                Home
              </button>
              <button
                onClick={() => handleNavigation('features')}
                className="hover:text-primary-300 transition-colors duration-200"
              >
                Features
              </button>
              <button
                onClick={() => handleNavigation('experience')}
                className="hover:text-primary-300 transition-colors duration-200"
              >
                Experience
              </button>
              <button
                onClick={() => handleNavigation('testimonial')}
                className="hover:text-primary-300 transition-colors duration-200"
              >
                Testimonials
              </button>
              <button
                onClick={() => handleNavigation('faq')}
                className="hover:text-primary-300 transition-colors duration-200"
              >
                FAQ
              </button>
            </div>
            <div className="flex flex-col items-start gap-2 md:gap-6 whitespace-nowrap">
              <span className="font-bold">Support</span>
              <a
                href="/support"
                className="hover:text-primary-300 transition-colors duration-200"
              >
                Feedback
              </a>
              <a
                href="/support"
                className="hover:text-primary-300 transition-colors duration-200"
              >
                Feature Request
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between w-full border-t-2 pt-8 border-white/10 md:items-center gap-6">
          <p className="text-center md:text-left w-full">
            Copyright ©2025 Bayaan. All rights reserved.
          </p>
          <div className="flex flex-row gap-2 md:gap-12 items-center w-full justify-center md:justify-end">
            <a href="/privacy" className="hover:text-primary-300 transition-colors duration-200">
              Privacy Policy
            </a>
            <a href="/terms" className="hover:text-primary-300 transition-colors duration-200">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
