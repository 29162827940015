import Card, { CardProps } from './Card';
import left from '@/assets/left.png';
import right from '@/assets/right.png';

function ExperienceSection() {
  const experiences: CardProps[] = [
    {
      title: 'Follow Along with Text',
      span: 'INTERACTIVE EXPERIENCE',
      description:
        'Synchronized text display while listening, with auto-scrolling and multiple translation options. Perfect for both learning and reflection.',
      image: left,
    },
    {
      title: 'Offline Access',
      span: 'ANYWHERE, ANYTIME',
      description:
        "Download entire surahs for offline listening. Your Qur'an companion works without internet, perfect for travel or areas with limited connectivity.",
      image: right,
    },
  ];
  return (
    <section id="experience" className="flex flex-col items-center w-full py-24">
      <div className="flex flex-col items-center text-primary-100 space-y-8 max-w-[1200px] text-center px-8">
        <span className="rounded-3xl bg-primary-300/10 px-4 py-2 font-semibold text-white text-xs md:text-base">
          USER EXPERIENCE
        </span>
        <h1 className="text-3xl/10 md:text-4xl/14 lg:text-5xl/18 max-w-[800px]">
          Designed for Your Spiritual Journey
        </h1>
        <div className="flex flex-col md:flex-row gap-8">
          {experiences &&
            experiences.map((experience, index) => (
              <Card
                title={experience.title}
                span={experience.span}
                description={experience.description}
                image={experience.image}
                flipped={experience.flipped}
                key={index}
              />
            ))}
        </div>
      </div>
    </section>
  );
}

export default ExperienceSection;
