import user1 from '@/assets/user1.png';
import user2 from '@/assets/user2.png';
import user3 from '@/assets/user3.png';
import user4 from '@/assets/user4.png';

export type TestimonialProps = {
  avatar: string;
  name: string;
  role: string;
  location: string;
  comment: string;
  feature: string;
  rating: number;
};

export const testimonials: TestimonialProps[] = [
  {
    avatar: user1,
    name: 'Faraz Hussein',
    role: 'AI Engineer',
    location: 'Coherent',
    comment:
      'The Bayaan App delivers a smooth, lag-free Quran experience with seamless reciter switching. The intuitive interface and Surah metadata make it easy to connect with the recitation while maintaining simplicity.',
    feature: 'User Experience',
    rating: 5,
  },
  {
    avatar: user2,
    name: 'Nadia Dedgeba',
    role: 'Technology Risk Practice',
    location: 'Lockton',
    comment:
      'Bayaan has revolutionized my Quran experience with quick access and diverse recitations in one place. The user-friendly navigation makes it effortless to integrate Quran into my daily routine.',
    feature: 'Accessibility',
    rating: 5,
  },
  {
    avatar: user3,
    name: 'Khadijah Abdalla',
    role: 'Political Science Student',
    location: 'De Anza College',
    comment:
      "Bayaan's seamless design provides instant, distraction-free access to the Quran. The organized interface makes it easy to find different Imams and recitations, perfect for daily spiritual practice.",
    feature: 'Seamless Experience',
    rating: 5,
  },
  {
    avatar: user4,
    name: 'Muzzammil Mohammed',
    role: 'Management Information Systems Student',
    location: 'SJSU',
    comment:
      "After switching from Spotify, Bayaan's dedicated platform and wide selection of reciters have transformed my Quran listening experience. The effortless reciter switching makes it truly special.",
    feature: 'Reciter Selection',
    rating: 5,
  },
  /* Commented out for future use
  {
    avatar: user1,
    name: "Ahmed Raza",
    role: "Islamic Studies Teacher",
    location: "Dubai, UAE",
    comment: "Bayaan has transformed how I teach the Qur'an. The synchronized text display and multiple rewayat options make it easier for my students to follow along and understand different recitation styles. The ad-free environment ensures a focused learning experience.",
    feature: "Teaching & Learning",
    rating: 5,
  },
  {
    avatar: user2,
    name: "Sarah Malik",
    role: "Medical Student",
    location: "London, UK",
    comment: "As a busy student, I love how Bayaan works offline. I download surahs before my commute and listen during my journey. The clean interface and lack of ads make it perfect for spiritual reflection during busy days.",
    feature: "Offline Access",
    rating: 5,
  },
  {
    avatar: user3,
    name: "Mohammed Ali",
    role: "Software Engineer",
    location: "Toronto, Canada",
    comment: "The technical quality of Bayaan is impressive. The app is fast, reliable, and the audio quality is excellent. I particularly appreciate the ability to switch between different rewayat and the educational information provided for each style.",
    feature: "Technical Quality",
    rating: 5,
  },
  {
    avatar: user4,
    name: "Aisha Rahman",
    role: "Homemaker",
    location: "Kuala Lumpur, Malaysia",
    comment: "Bayaan has become my daily companion for Qur'an recitation. The vast collection of reciters and the ability to follow along with the text has helped me improve my recitation. The fact that it's completely free and ad-free shows their commitment to serving the community.",
    feature: "Daily Practice",
    rating: 5,
  },
  {
    avatar: user1,
    name: "Yusuf Khan",
    role: "Business Executive",
    location: "Singapore",
    comment: "The variety of reciters and rewayat on Bayaan is exceptional. I can explore different recitation styles and learn about their historical significance. The educational content about each rewayah adds depth to my understanding.",
    feature: "Multiple Rewayat",
    rating: 5,
  },
  {
    avatar: user2,
    name: "Fatima Hassan",
    role: "Qur'an Teacher",
    location: "Cairo, Egypt",
    comment: "As a Qur'an teacher, I appreciate how Bayaan maintains the dignity of the Qur'an. No ads, no distractions, just pure recitation. The synchronized text feature helps my students follow along perfectly.",
    feature: "Teaching & Learning",
    rating: 5,
  },
  {
    avatar: user3,
    name: "Omar Patel",
    role: "Travel Consultant",
    location: "Mumbai, India",
    comment: "Bayaan's offline feature is a blessing for frequent travelers. I can download my favorite surahs and listen to them during long flights or in areas with poor connectivity. The app's reliability is impressive.",
    feature: "Offline Access",
    rating: 5,
  },
  {
    avatar: user4,
    name: "Zainab Ahmed",
    role: "University Professor",
    location: "Istanbul, Turkey",
    comment: "The educational value of Bayaan is outstanding. Each reciter's profile includes their background and expertise, helping users understand the rich tradition of Qur'anic recitation. The app truly serves both beginners and advanced learners.",
    feature: "Educational Value",
    rating: 5,
  },
  {
    avatar: user1,
    name: "Hassan Ibrahim",
    role: "Retired Imam",
    location: "Dakar, Senegal",
    comment: "Bayaan's commitment to providing a free, ad-free platform for the Qur'an is commendable. The app's interface is clean and respectful, making it suitable for all age groups. The multiple rewayat options help preserve our rich recitation heritage.",
    feature: "Community Service",
    rating: 5,
  },
  {
    avatar: user2,
    name: "Maryam Khan",
    role: "Stay-at-Home Parent",
    location: "Birmingham, UK",
    comment: "I use Bayaan daily with my children. The synchronized text display helps them learn to read the Qur'an while listening. The app's simplicity and lack of ads make it perfect for family use.",
    feature: "Family Learning",
    rating: 5,
  },
  {
    avatar: user3,
    name: "Abdullah Rahman",
    role: "Mobile Developer",
    location: "Jakarta, Indonesia",
    comment: "The technical implementation of Bayaan is flawless. The app loads quickly, handles offline content efficiently, and provides a smooth user experience. The audio quality is consistently excellent across all recitations.",
    feature: "Technical Quality",
    rating: 5,
  },
  {
    avatar: user4,
    name: "Layla Hassan",
    role: "Graduate Student",
    location: "Berlin, Germany",
    comment: "Bayaan has become an essential part of my daily spiritual routine. The ability to download surahs and listen offline during my commute is invaluable. The app's clean interface helps me focus on the recitation.",
    feature: "Daily Practice",
    rating: 5,
  },
  */
];
